var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.fakeData),function(data,index){return _c('div',{key:index,staticClass:"channelCardLoading position-relative st_background_white mx-auto d-flex align-items-center",class:{
      'px-md-4': !_vm.mobileStyle,
      'channelCardLoading--sidbar': _vm.mobileStyle,
      'mb-3': index + 1 !== _vm.fakeData.length
    }},[_c('div',{staticClass:"w-100"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center",class:{
            'mb-4': _vm.mobileStyle,
            'mb-4 mb-md-0': !_vm.mobileStyle
          }},[_c('div',{staticClass:"w-100",class:{
              'pr-md-4': !_vm.mobileStyle
            }},[_c('div',{staticClass:"channelCardLoading__text channelCardLoading__text--large channelCardLoading__text--large-m"}),_vm._v(" "),_c('div',{staticClass:"channelCardLoading__text channelCardLoading__text--large mb-3"}),_vm._v(" "),_c('div',{staticClass:"channelCardLoading__text channelCardLoading__text--large channelCardLoading__text--large-m"}),_vm._v(" "),_c('div',{staticClass:"channelCardLoading__text channelCardLoading__text--small"})])]),_vm._v(" "),_c('b-col',{staticClass:"channelCardLoading__right position-relative d-flex align-items-center",class:{
            'mb-4': _vm.mobileStyle,
            'mb-4 mb-md-0': !_vm.mobileStyle
          }},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"channelCardLoading__text mb-3 mx-auto"}),_vm._v(" "),_c('div',{staticClass:"channelCardLoading__text mx-auto"})])])],1)],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }