<template>
  <div class="newsBlock">
    <h5 class="newsBlock__title st_color_sub mb-0">
      最新觀點
    </h5>
    <div class="newsBlock__item pl-3 pr-4">
      <div class="newsBlock__item__text mt-3" />
      <div class="newsBlock__item__text2 mt-3" />
    </div>
    <div v-for="el in articleArray" :key="el" class="newsBlock__item pl-3 pr-4">
      <div class="newsBlock__item__text mt-3" />
      <div class="newsBlock__item__text2 mt-3" />
    </div>
    <div class="newsBlock__item pl-3 pr-4">
      <div class="newsBlock__item__text mt-3" />
      <div class="newsBlock__item__text2 mt-3" />
    </div>
    <div class="newsBlock__item__last pl-3 pr-4 pb-3">
      <div class="newsBlock__item__text mt-3" />
      <div class="newsBlock__item__text2 mt-3" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      articleArray: {
        type:    Array,
        default: () => [1],
      },
    },
  }
</script>

<style lang="scss" scoped>
  .newsBlock {
    padding: 0;
    background: white;

    &__title {
      text-align: center;
      padding: 10px 0;
      border-top: 4px solid $st_color_sub;
      font-size: $st_font-size-bigger;
      font-weight: $st_font-weight-medium;
      letter-spacing: $st_font_letter-spacing;
      border-bottom: dashed 1px rgba(184, 188, 195, 0.5);
    }

    &__item {
      height: 50px;
      border-bottom: dashed 1px rgba(184, 188, 195, 0.5);

      &__text, &__text2 {
        height: 8px;
        width: 100%;
        border-radius: 4px;

        @include animation;
      }

      &__text2 {
        width: 30%;
      }

      &__last {
        border-bottom: none;
      }
    }
  }
</style>
