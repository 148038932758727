<template>
  <b-modal
    id="followExpertButNotLoginModal"
    centered
    size="md"
    modal-class="followExpertButNotLoginModal"
    header-class="followExpertButNotLoginModal__header border-0 pb-0"
    body-class="followExpertButNotLoginModal__body pt-3 pt-md-1 pb-4 d-flex flex-column align-items-center"
    footer-class="followExpertButNotLoginModal__footer border-0 pt-3 pt-md-1 pb-5 mb-md-n2">
    <span class="st_main-medium-bigger mb-4">
      關注{{ $userCheck.is('service') ? '達人' : '組織' }}粉絲專頁
    </span>
    <ScanTraderAvatar class="mb-2" width="80" height="80" :src="user.user_info.avatar" />
    <span class="st_caption-medium-base d-flex align-items-center mb-4 followExpertButNotLoginModal__username">
      <svg-icon
        class="mr-2" :width="$userBadge(user.user_info, 'width', '', 0.4)"
        :height="$userBadge(user.user_info, 'height', '', 0.4)" :name="$userBadge(user.user_info, 'name')" />
      <nuxt-link v-if="$userCheck.is('service', user.user_info) && _.get(user.user_info, 'belongOrgInfo.name', 0)" :to="$userRoute({ name: 'o-oas' }, user.user_info.belongOrgInfo)">
        <svg-icon
          v-b-tooltip.hover.focus.top="user.user_info.belongOrgInfo.name.nick"
          class="mr-2"
          :width="$userBadge(user.user_info.belongOrgInfo, 'width', '', 0.4)"
          :height="$userBadge(user.user_info.belongOrgInfo, 'height', '', 0.4)"
          :name="$userBadge(user.user_info.belongOrgInfo, 'name')" />
      </nuxt-link>
      <span class="text-ellipsis ">
        {{ user.user_info.name.nick }}{{ (user.user_info.belongOrg && _.get(user.user_info, 'name.real', '')) ? `．${user.user_info.name.real || ''}` : '' }}
      </span>
    </span>
    <span class="st_caption-none-base followExpertButNotLoginModal__attention text-center text-md-left">
      登入 LINE 並加入微股力好友，即可收到{{ $userCheck.is('service') ? '達人' : '組織' }}的最新消息！
    </span>
    <template slot="modal-footer">
      <b-container
        class="p-0 d-flex justify-content-center"
        fluid>
        <a
          class="followExpertButNotLoginModal__login st_highlight-medium-big w-100 d-flex justify-content-center align-items-center"
          :href="user.loginUrl">
          登入 LINE 帳號
        </a>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters(['user']),
    },
    mounted () {
      (!this.$userCheck.isLogin()
        && this.$userCheck.are(['service', 'organization'])
        && this.$userRouteActive('')
        && 'follow' in this.$route.query)
        && this.$root.$emit('bv::show::modal', 'followExpertButNotLoginModal')
    },
  }
</script>

<style lang="scss">
  .followExpertButNotLoginModal {
    .modal-dialog {
      @include media-breakpoint-down(sm) {
        max-width: 345px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__header {
      button {
        font-size: 30px;
        line-height: 18px;
      }
    }

    &__username {
      max-width: 260px;

      @include media-breakpoint-up(md) {
        max-width: 380px;
      }
    }

    &__attention {
      max-width: 236px;

      @include media-breakpoint-up(md) {
        max-width: none;
      }
    }

    &__login {
      max-width: 235px;
      height: 50px;
      background-color: #00c43e;
      border-radius: $st_border-radius;
      transition: opacity 0.5s;

      &:hover {
        color: $white;
        opacity: 0.8;
      }
    }
  }
</style>
