<template>
  <div class="news-block" v-if="articles && articles.length">
    <h5 class="news-block-title st_color_sub mb-0">
      最新觀點
    </h5>
    <nuxt-link
      :to="{ name: 'article-id', params: { id: article.identity } }" v-for="(article, index) in articles" :key="article.identity"
      class="news d-flex justify-content-between align-items-center" :class="'views-row-'+(index+1)">
      <b-container>
        <b-row>
          <b-col class="p-0">
            <b-container>
              <b-row>
                <div class="title st_color_main_light">
                  {{ article.title }}
                </div>
              </b-row>
              <b-row class="float-left">
                <div class="info col-auto p-0">
                  <span class="date st_caption-regular-smaller">
                    {{ $utils.timeFormatDefault(article.published) }}
                  </span>
                </div>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </nuxt-link>
  </div>
</template>

<script>
  export default {
    props: {
      articles: {
        type:    Array,
        default: () => [],
      },
    },
  }
</script>

<style lang="scss" scoped>
  .news-block {
    padding: 0;
    background: white;

    .news-block-title {
      text-align: center;
      padding: 10px 0;
      border-top: 4px solid $st_color_sub;
      font-size: $st_font-size-bigger;
      font-weight: $st_font-weight-medium;
      letter-spacing: $st_font_letter-spacing;
      border-bottom: dashed 1px rgba(184, 188, 195, 0.5);
    }

    .news {
      padding: 8px 15px;
      display: block;
      clear: both;
      border-bottom: 1px dashed rgba(184, 188, 195, 0.5);
      background: white;
      position: relative;

      &:last-child {
        border: none;
      }

      .title {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        word-break: break-all;
        font-size: $st_font-size-base;
        letter-spacing: $st_font_letter-spacing;
        color: $st_color_main_light;
      }

      .date {
        font-size: $st_font-size-smaller;
        letter-spacing: 0.6px;
        color: #76787d;
      }

      &:hover,
      &.nuxt-link-exact-active {
        background: #f7faff;

        .title {
          color: black;
        }
      }
    }
  }
</style>
