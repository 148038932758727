<template>
  <div class="block">
    <div v-if="hasTitle" class="block-heading">
      <slot name="title-prefix" />
      <h2 v-if="title" class="block-title" :class="titleClass">
        <client-only v-if="icon">
          <svg-icon :name="icon" :width="iconSize.w" :height="iconSize.h" />
        </client-only>
        {{ title }}
      </h2>
      <slot name="title-suffix" />
    </div>
    <div v-if="hasContent" class="block-content" :class="contentClass">
      <slot name="content-prefix" />
      <slot />
      <slot name="content-suffix" />
    </div>
  </div>
</template>

<script>
  export default {
    name:  'Block',
    props: {
      title: {
        type:    String,
        default: '',
      },
      titleClass: {
        type:    String,
        default: '',
      },
      icon: {
        type:    String,
        default: null,
      },
      iconSize: {
        type: Object,
        default () {
          return {
            w: '22',
            h: '22',
          }
        },
      },
      contentClass: {
        type:    String,
        default: '',
      },
    },
    computed: {
      hasTitle () {
        return !!(
          this.title || this.$slots['title-prefix'] || this.$slots['title-suffix']
        )
      },
      hasIcon () {
        return !!this.icon
      },
      hasContent () {
        return !!(
          this.$slots.default || this.$slots['content-prefix'] || this.$slots['content-suffix']
        )
      },
    },
  }
</script>

<style lang="scss">

</style>
