<template>
  <div>
    <div
      :key="index"
      v-for="(data, index) in fakeData"
      :class="{
        'px-md-4': !mobileStyle,
        'channelCardLoading--sidbar': mobileStyle,
        'mb-3': index + 1 !== fakeData.length
      }"
      class="channelCardLoading position-relative st_background_white mx-auto d-flex align-items-center">
      <div class="w-100">
        <b-row>
          <b-col
            :class="{
              'mb-4': mobileStyle,
              'mb-4 mb-md-0': !mobileStyle
            }"
            class="d-flex align-items-center">
            <div
              :class="{
                'pr-md-4': !mobileStyle
              }"
              class="w-100">
              <div class="channelCardLoading__text channelCardLoading__text--large channelCardLoading__text--large-m" />
              <div class="channelCardLoading__text channelCardLoading__text--large mb-3" />
              <div class="channelCardLoading__text channelCardLoading__text--large channelCardLoading__text--large-m" />
              <div class="channelCardLoading__text channelCardLoading__text--small" />
            </div>
          </b-col>
          <b-col
            :class="{
              'mb-4': mobileStyle,
              'mb-4 mb-md-0': !mobileStyle
            }"
            class="channelCardLoading__right position-relative d-flex align-items-center">
            <div class="w-100">
              <div class="channelCardLoading__text mb-3 mx-auto" />
              <div class="channelCardLoading__text mx-auto" />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      fakeData: {
        type:    Array,
        default: () => [1],
      },
      mobileStyle: {
        type:    Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .channelCardLoading {
    height: 280px;
    box-shadow: 0 0 6px 0 #b6b9be;
    padding: 0 29px;
    $channelCardLoading: &;

    &--sidbar {
      box-shadow: none;
    }

    &:not(&--sidbar) {
      max-width: 320px;

      @include media-breakpoint-up(sm) {
        max-width: 456px;
        padding: 0 40px;
      }

      @include media-breakpoint-up(md) {
        max-width: 608px;
        height: 182px;
        border-radius: 5px;
        border-style: solid;
        border-width: 2px;
        border-image-source: linear-gradient(to right, #fafafa, #f2f2f2);
        border-image-slice: 1;
        box-shadow: none;

        &::before {
          position: absolute;
          right: 227px;
          top: 22px;
          border-radius: 5px;
          border-right-style: solid;
          border-right-width: 2px;
          border-image-source: linear-gradient(to right, #fafafa, #f2f2f2);
          border-image-slice: 1;
          height: calc(100% - 44px);
          max-height: 164px;
          content: "";
        }
      }

      #{$channelCardLoading}__text {
        @include media-breakpoint-up(sm) {
          max-width: 200px;
        }

        @include media-breakpoint-up(md) {
          max-width: 140px;
        }

        &--large {
          @include media-breakpoint-up(sm) {
            max-width: 376px;
          }

          @include media-breakpoint-up(md) {
            max-width: 320px;
          }
        }

        &--large-m {
          @include media-breakpoint-up(md) {
            margin-bottom: 34px;
          }
        }

        &--small {
          @include media-breakpoint-up(sm) {
            max-width: 146px;
          }

          @include media-breakpoint-up(md) {
            max-width: 120px;
          }
        }
      }

      #{$channelCardLoading}__right {
        @include media-breakpoint-up(md) {
          flex-basis: 210px;
          max-width: 210px;
          padding-top: 0;

          &::before {
            display: none;
          }
        }
      }
    }

    &__text {
      height: 8px;
      border-radius: 4px;
      width: 100%;
      max-width: 150px;

      @include animation;

      &--large {
        max-width: 258px;
      }

      &--large-m {
        margin-bottom: 27px;
      }

      &--small {
        max-width: 100px;
      }
    }

    &__right {
      flex-basis: 100%;
      max-width: 100%;
      padding-top: 37px;

      &::before {
        position: absolute;
        right: 16px;
        top: 0;
        border-top: 2px solid $st_color_gray_lighter;
        width: calc(100% - 32px);
        content: "";
      }
    }
  }
</style>
